<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <div style="height: 100%">
      <!-- 条件搜索 -->
      <div class="filter-box">
        <div class="row">
          <SelectPlatformChannel :params="params" data-type="obj" />

          <el-select
            style="width: 120px"
            v-model="categoryId"
            filterable
            clearable
            placeholder="选择标签"
          >
            <el-option
              v-for="item in selectItems"
              :key="item.categoryId"
              :value="item.categoryId"
              :label="item.categoryName"
            >
              <span style="float: left">{{ item.categoryName }}</span>
            </el-option>
          </el-select>

          <el-select
            v-model="adminUserId"
            placeholder="请选择用户"
            filterable
            style="width: 120px"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in userList"
              :key="item.id"
            ></el-option>
          </el-select>

          <el-date-picker
            v-model="yearMoth"
            type="month"
            placeholder="选择年月"
            :picker-options="pickerOptionsC"
          >
          </el-date-picker>

          <el-button
            type="primary"
            style="margin-left: 10px"
            :loading="loading"
            @click="search"
          >
            搜索
          </el-button>

          <el-button type="info" @click="handleReset" :loading="loading">
            重置
          </el-button>

          <el-button type="primary" class="primary" @click="handleAdd">
            新建
          </el-button>
        </div>

        <div style="margin-top: 10px">
          <div class="statistics-card">
            <div class="data-label">充值</div>
            <div class="statistic-num-text">
              <span class="statistic-num">{{ totalDetail.totalAmount }}</span>
            </div>
          </div>

          <div class="statistics-card">
            <div class="data-label">pv</div>
            <div class="statistic-num-text">
              <span class="statistic-num">{{ totalDetail.pvTotal }}</span>
            </div>
          </div>

          <div class="statistics-card">
            <div class="data-label">uv</div>
            <div class="statistic-num-text">
              <span class="statistic-num">{{ totalDetail.uvTotal }}</span>
            </div>
          </div>
        </div>
      </div>

      <el-table
        ref="userConsumeTable"
        header-row-class-name="table-header"
        :data="tableData"
        style="width: 100%"
        class="tableClass"
        v-loading="tableLoading"
      >
        <el-table-column prop="channelName" label="公众号"> </el-table-column>

        <template v-if="headerData.length > 0">
          <el-table-column
            v-for="(item, index) in headerData"
            :key="index"
            :label="item"
            min-width="120px"
          >
            <template slot-scope="scope">
              <el-popover
                v-if="scope.row.lists[index]"
                placement="bottom-start"
                title="修改"
                width="270"
                trigger="click"
                @show="popoverShow(scope.row.lists[index])"
              >
                <div>
                  <div class="inputNumber" style="margin-bottom: 10px">
                    <div style="margin-bottom: 5px">充值</div>
                    <el-input-number
                      v-model="amount"
                      controls-position="right"
                    ></el-input-number>
                  </div>

                  <div class="inputNumber" style="margin-bottom: 10px">
                    <div style="margin-bottom: 5px">pv</div>
                    <el-input-number
                      v-model="pv"
                      controls-position="right"
                    ></el-input-number>
                  </div>

                  <div class="inputNumber" style="margin-bottom: 10px">
                    <div style="margin-bottom: 5px">uv</div>
                    <el-input-number
                      v-model="uv"
                      controls-position="right"
                    ></el-input-number>
                  </div>

                  <div class="inputNumber" style="margin-bottom: 10px">
                    <el-button
                      type="primary"
                      @click="confirm(scope.row.lists[index])"
                      style="margin-left: 15px"
                      :loading="editLoading"
                      >确 定
                    </el-button>
                  </div>
                </div>

                <div style="cursor: pointer" slot="reference">
                  <i class="remark-num">
                    充值：{{
                      scope.row.lists[index]
                        ? scope.row.lists[index].amount
                        : '无'
                    }}</i
                  >
                  <p style="line-height: 20px">
                    pv：{{
                      scope.row.lists[index] ? scope.row.lists[index].pv : '无'
                    }}
                  </p>
                  <span
                    >uv：{{
                      scope.row.lists[index] ? scope.row.lists[index].uv : '无'
                    }}</span
                  >
                </div>
              </el-popover>

              <div v-else>
                <i class="remark-num">
                  充值：{{
                    scope.row.lists[index]
                      ? scope.row.lists[index].amount
                      : '无'
                  }}</i
                >
                <p style="line-height: 20px">
                  pv：{{
                    scope.row.lists[index] ? scope.row.lists[index].pv : '无'
                  }}
                </p>
                <span
                  >uv：{{
                    scope.row.lists[index] ? scope.row.lists[index].uv : '无'
                  }}</span
                >
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>

      <el-pagination
        style="margin: 10px 0; text-align: right"
        background
        @current-change="handleStaticPageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog title="新建" :visible.sync="dialogVisible" width="40%">
      <div>
        <div class="add_item">
          <div class="add_item_t">公众号：</div>
          <el-select
            v-model="accountChannelId"
            filterable
            placeholder="请选择公众号"
          >
            <el-option
              v-for="item in officialList"
              :key="item.id"
              :label="item.channelName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="add_item">
          <div class="add_item_t">年月：</div>
          <el-date-picker
            v-model="saveDate"
            type="month"
            placeholder="选择年月"
            :picker-options="pickerOptionsC"
          >
          </el-date-picker>
        </div>
        <div class="add_item">
          <div class="add_item_t">pv：</div>
          <el-input
            v-model="addPv"
            type="number"
            placeholder="请输入pv"
          ></el-input>
        </div>

        <div class="add_item">
          <div class="add_item_t">uv：</div>
          <el-input
            v-model="addUv"
            type="number"
            placeholder="请输入uv"
          ></el-input>
        </div>

        <div class="add_item">
          <div class="add_item_t">充值：</div>
          <el-input
            v-model="addAmount"
            type="number"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAdd" :loading="addLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>


<script>
import SelectPlatformChannel from '@/components/SelectPlatformChannel'
import { getClassifyList } from '@/api/classify'
import { tagLinkData, editTagLinkData, addTagLinkData } from '@/api/tagLink'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { pickerOptionsC } from '@/assets/js/options'
export default {
  name: 'TagLink',
  components: {
    SelectPlatformChannel,
  },
  computed: {
    ...mapGetters(['officialList', 'userList']),
  },
  data() {
    return {
      params: {}, //参数
      selectItems: [], //标签数据源
      categoryId: '', //标签
      loading: false,
      tableData: [],
      headerData: [],
      tableLoading: false,
      amount: 0,
      pv: 0,
      uv: 0,
      editLoading: false,
      dialogVisible: false,
      optionsGzh: [],
      accountChannelId: '',
      saveDate: '',
      addPv: '',
      addUv: '',
      addAmount: '',
      addLoading: false,
      page: 1,
      pageSize: 30,
      total: 0,
      dataList: [],
      pickerOptionsC,
      totalDetail: {},
      adminUserId: '',
      yearMoth: '',
    }
  },

  methods: {
    initData() {
      getClassifyList({ type: 1, status: 1 }).then((res) => {
        this.selectItems = res.map((item) => {
          return {
            categoryId: item.id,
            categoryName: item.name,
          }
        })
      })
    },

    handleReset() {
      this.params = {}
      this.categoryId = ''
      this.yearMoth = ''
      this.adminUserId = ''
      this.handlePageChange(1)
    },

    // 分页操作
    handlePageChange(page) {
      this.page = page
      this.getList()
    },

    search() {
      this.handlePageChange(1)
    },

    // 静态分页
    handleStaticPageChange(page) {
      this.page = page
      this.tableData = this.dataList.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      )
    },

    async getList() {
      try {
        let params = {
          platform: this.params.originPlatform,
          accountChannelId: this.params.currentOfficial
            ? this.params.currentOfficial.id
            : '',
          categoryId: this.categoryId,
          yearMoth: this.yearMoth ? dayjs(this.yearMoth).format('YYYY-MM') : '',
          adminUserId: this.adminUserId,
        }

        this.tableLoading = true
        const { headers, list, total } = await tagLinkData(params)
        this.tableLoading = false
        this.headerData = headers
        this.dataList = list
        this.total = list.length
        this.totalDetail = total
        this.handleStaticPageChange(1)
      } catch (error) {
        console.log(error)
        this.tableLoading = false
      }
    },

    popoverShow(info) {
      if (!info) return
      let amount = info.amount.replace(/,/g, '')
      let pv = info.pv.replace(/,/g, '')
      let uv = info.uv.replace(/,/g, '')
      this.amount = Number(amount) || 0
      this.pv = Number(pv) || 0
      this.uv = Number(uv) || 0
    },

    thousands2(num) {
      var str = num.toString()
      var reg = str.indexOf('.') > -1 ? /\B(?=(\d{3})+\.)/g : /\B(?=(\d{3})+$)/g
      return str.replace(reg, ',')
    },

    async confirm(info) {
      let data = {
        pv: this.pv,
        uv: this.uv,
        amount: this.amount,
      }
      try {
        this.editLoading = true
        await editTagLinkData(info.id, data)
        this.editLoading = false
        // 静态更新
        info.pv = this.thousands2(this.pv.toFixed(2))
        info.uv = this.thousands2(this.uv.toFixed(2))
        info.amount = this.thousands2(this.amount.toFixed(2))

        document.body.click()
        this.$message.success('编辑成功')
      } catch (error) {
        this.editLoading = false
      }
    },

    async confirmAdd() {
      let data = {
        accountChannelId: this.accountChannelId,
        saveDate: dayjs(this.saveDate).format('YYYY-MM'),
        pv: Number(this.addPv) || 0,
        uv: Number(this.addUv) || 0,
        amount: Number(this.addAmount) || 0,
      }
      try {
        this.addLoading = true
        await addTagLinkData(data)
        this.addLoading = false
        this.$message.success('新增成功')
        this.getList()
        this.dialogVisible = false
      } catch (error) {
        this.addLoading = false
      }
    },

    handleAdd() {
      this.dialogVisible = true
    },
  },
  mounted() {
    this.initData()
    this.getList()

    this.$nextTick(() => {
      this.$refs.userConsumeTable.doLayout()
    })
  },
}
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
  height: 100%;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
.el-table--small {
  font-size: 12px !important;
}

.inputNumber {
  div {
    width: 100%;
  }

  button {
    width: 100%;
    margin-left: 0 !important;
  }
}

.primary {
  background-color: rgb(82, 143, 255);
  border-color: rgb(82, 143, 255);
}

.add_item {
  margin-bottom: 30px;
}

.add_item_t {
  width: 80px;
  margin-bottom: 5px;
}

/deep/.el-table {
  height: calc(100% - 180px);
  display: flex;
  flex-direction: column;
}

/deep/.el-table__header-wrapper {
  height: 56px;
  flex-shrink: 0;
}

/deep/.el-table__body-wrapper {
  overflow-x: scroll;
  overflow-y: scroll;
}
</style>
