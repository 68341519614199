import request from "./config";

// 标签链接统计
export function tagLinkData(params) {
  return request.get("/statistics/tagLinkData", { params });
}

// 修改
export function editTagLinkData(id, data) {
  return request.put(`/statistics/tagLinkData/${id}`, data);
}

// 新建
export function addTagLinkData(data) {
  return request.post(`/statistics/tagLinkData`, data);
}
